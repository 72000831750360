<template>
    <b-row>
        <b-col v-if="address.length > 0" sm="12">
            <b-alert variant="info" show dismissible>
                <ol>
                    <li v-for="(item, index) in address" :key="index">
                        <strong v-for="(it, idx) in item" :key="idx">{{ it }} </strong>
                        <b-button variant="primary" size="sm" @click="selectAddress(index)">{{ $t('using') }}</b-button>
                    </li>
                </ol>
            </b-alert>
        </b-col>
        <b-col sm="6">
            <div class="form-group">
                <label for="delivery_method">{{ $t('delivery_method') }}<span class="text-danger">*</span></label>
                <select
                name="delivery_method"
                class="form-control"
                v-model="orderData.delivery.delivery_method"
                @change="changeDeliveryMethod">
                    <option v-for="(item, index) in deliveries" :key="index" :value="item.slug">{{ item.name }}</option>
                </select>
            </div>
            <div class="form-group" v-if="orderData.delivery.delivery_method === 'nova-posta'">
                <label for="delivery_type">{{ $t('delivery_type') }}<span class="text-danger">*</span></label>
                <select
                v-model="orderData.delivery.delivery_type"
                name="delivery_type"
                class="form-control">
                    <option v-for="(item, index) in deliveryTypes[orderData.delivery.delivery_method]" :value="item.id" :key="index">{{ item.name }}</option>
                </select>
            </div>
            <!-- UKR-poshta -->
            <UkrDeliveryType />
        </b-col>
        <b-col sm="6" v-if="orderData.delivery.delivery_method === 'nova-posta'">
            <div class="form-group">
                <label for="city">{{ $t('city_recipient') }}<span class="text-danger">*</span></label>
                <model-list-select
                    :list="cities"
                    option-value="ref"
                    option-text="name"
                    v-model="orderData.delivery.cityRecipient"
                    :placeholder="$t('nothing_selected')"
                    @searchchange="searchCity"></model-list-select>
            </div>
            <div class="form-group" v-if="orderData.delivery.delivery_type === 'WarehouseWarehouse'">
                <label for="warehouse">{{ $t('warehouse_or_street') }}<span class="text-danger">*</span></label>
                <!--<model-list-select
                    :list="warehouses"
                    option-value="Description"
                    option-text="Description"
                    v-model="orderData.delivery.warehouse"
                    :placeholder="$t('nothing_selected')"
                    @searchchange="searchWarehouse"></model-list-select>-->
                    <!--<select v-if="warehouses" v-model="orderData.delivery.warehouse" class="form-control">
                        <option v-for="(item, idx) in warehouses" :key="idx">{{ item.Description }}</option>
                    </select>-->
                    <list-select
                    :list="warehouses"
                    option-value="Ref"
                    option-text="Description"
                    :selected-item="{ Ref: orderData.delivery.warehouse_ref, Description: orderData.delivery.warehouse }"
                    :placeholder="$t('nothing_selected')"
                    @select="onSelectWarehouse"></list-select>
            </div>
            <div class="form-group" v-if="orderData.delivery.delivery_type === 'WarehouseDoors'">
                <label for="address">Вулиця<span class="text-danger">*</span></label>
                <model-list-select
                    :list="streets"
                     option-value="ref"
                    option-text="name"
                    v-model="orderData.delivery.street_ref"
                    :selected-item="{ ref: orderData.delivery.street_ref, name: orderData.delivery.street }"
                    :placeholder="$t('nothing_selected')"
                    @searchchange="searchStreet"></model-list-select>
            </div>
            <b-input-group v-if="orderData.delivery.delivery_type === 'WarehouseDoors'">
                <b-form-input placeholder="№ дому" v-model="orderData.delivery.house_number"></b-form-input>
                <b-form-input :placeholder="$t('flat')" v-model="orderData.delivery.flat"></b-form-input>
                <b-form-input placeholder="№ квартири" v-model="orderData.delivery.apartment_number"></b-form-input>
            </b-input-group>
            <b-form-group :label="$t('recipient')">
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="individual">{{ $t('fiz_lico') }}</b-form-radio>
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="legal">{{ $t('ur_lico') }}</b-form-radio>
            </b-form-group>
        </b-col>
        <b-col sm="6" v-else-if="orderData.delivery.delivery_method === 'samoviviz'">
            <div class="form-group">
                <label for="city">{{ $t('area') }}/{{ $t('city') }}</label>
                <input type="text" class="form-control" v-model="orderData.delivery.city_name">
            </div>
            <b-form-group :label="$t('recipient')">
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="individual">{{ $t('fiz_lico') }}</b-form-radio>
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="legal">{{ $t('ur_lico') }}</b-form-radio>
            </b-form-group>
        </b-col>
        <UkrPostaTarget v-else-if="orderData.delivery.delivery_method === 'ukr-posta'"/>
        <b-col sm="6" v-else>
            <div class="form-group">
                <label for="city">{{ $t('area') }}/{{ $t('city') }}<span class="text-danger">*</span></label>
                <input type="text" class="form-control" v-model="orderData.delivery.city_name">
            </div>
            <div class="form-group">
                <label for="zip_code">{{ $t('index') }}<span class="text-danger">*</span></label>
                <input type="text" name="zip_code" class="form-control" v-model="orderData.delivery.zip_code">
            </div>
            <b-form-group :label="$t('recipient')">
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="individual">{{ $t('fiz_lico') }}</b-form-radio>
                <b-form-radio v-model="orderData.delivery.entity" name="entity" value="legal">{{ $t('ur_lico') }}</b-form-radio>
            </b-form-group>
        </b-col>
    </b-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import { ModelListSelect, ListSelect } from 'vue-search-select'
import UkrPostaTarget from './ukrPoshta/target'
import UkrDeliveryType from './ukrPoshta/deliveryType'
export default {
    data: () => ({
        selectedCity: '',
        cities: [],
        searchCityText: '',
        warehouses: [],
        searchWarehouseText: '',
        streets: [],
        componentKey: 0
    }),
    components: {
        ModelListSelect,
        ListSelect,
        UkrPostaTarget,
        UkrDeliveryType
    },
    watch: {
        'orderData.delivery.cityRecipient': function (val) {
            this.searchWarehouse()
        },
        'orderData.delivery.street_ref': function () {
            this.getStreetByKey()
        }
    },
    computed: {
        ...mapGetters({
            orderData: 'order/order',
            deliveries: 'order/deliveries',
            deliveryTypes: 'order/deliveryTypes'
        }),
        address () {
            const deliveryData = []
            if (this.orderData.client.addresses) {
                this.orderData.client.addresses.forEach(address => {
                    const delivery = this.deliveries.find(d => d.id === address.delivery_id)
                    deliveryData.push([
                        (delivery && delivery.name) ? delivery.name : null,
                        (address.delivery_type === 'WarehouseWarehouse') ? 'Склад-Склад' : 'Склад-Двері',
                        address.warehouse_name,
                        address.city_name,
                        address.street_name,
                        address.zip_code,
                        address.entity === 'individual' ? 'Фіз.особа' : 'Юр.особа'
                    ])
                })
            }
            return deliveryData
        }
    },
    methods: {
        ...mapActions({
            changeDeliveryType: 'order/changeDeliveryType',
            setDeliveryData: 'order/setDeliveryData',
            updateStatuses: 'order/updateStatuses'
        }),
        async searchCity (value) {
            if (value.length > 1) {
                this.searchCityText = value
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'city/search', { key: value }).then(resp => {
                    this.cities = resp.data.data
                    this.warehouses = []
                })
            }
        },
        async searchWarehouse () {
            this.$root.$refs.loading.start()
            if (this.orderData.delivery.cityRecipient) {
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                const { data } = await axios.post(apiUrl + 'city/warehouse/cityref', { city: this.orderData.delivery.cityRecipient, key: '' })
                this.warehouses = data.data
            }
            this.$root.$refs.loading.finish()
        },
        async searchStreet (value) {
            this.$root.$refs.loading.start()
            if (this.orderData.delivery.cityRecipient) {
                if (value.length > 1) {
                    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                    const { data } = await axios.post(apiUrl + 'city/street', { city: this.orderData.delivery.cityRecipient, key: value })
                    this.streets = data
                }
            }
            this.$root.$refs.loading.finish()
        },
        async changeDeliveryMethod () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const products = this.orderData.products.map(p => {
                return p.id
            })
            console.log(this.orderData.delivery)
            const citySender = this.deliveries.find(d => d.slug === this.orderData.delivery.delivery_method)
            if (this.orderData.delivery.delivery_method === 'nova-posta') {
                this.orderData.delivery.delivery_type = 'WarehouseWarehouse'
                this.orderData.delivery.entity = 'individual'
                if (this.orderData.delivery.cityRecipient) {
                    this.$root.$refs.loading.start()
                    const { data } = await axios.post(apiUrl + 'deliveries/price', {
                        products: products,
                        citySender: citySender.city_ref,
                        cityRecipient: this.orderData.delivery.cityRecipient,
                        deliveryType: this.orderData.delivery.delivery_type,
                        deliveryMethod: this.orderData.delivery.delivery_method
                    })
                    this.setDeliveryData(data)
                    this.$root.$refs.loading.finish()
                }
            } else if (this.orderData.delivery.delivery_method === 'ukr-posta') {
                this.orderData.delivery.delivery_type = 'W2W'
                this.orderData.delivery.shiping_type = 'EXPRESS'
                this.setDeliveryData({ deliveryCost: 0, deliveryDate: null })
            } else {
                this.setDeliveryData({ deliveryCost: 0, deliveryDate: null })
            }
            this.updateStatuses()
        },
        async getStreetByKey () {
            if (this.orderData.delivery.delivery_type === 'nova-posta') {
                this.$root.$refs.loading.start()
                if (this.orderData.delivery.street_ref) {
                    const street = this.streets.find(s => s.ref === this.orderData.delivery.street_ref)
                    this.orderData.delivery.street = street.name
                }
                this.$root.$refs.loading.finish()
            }
        },
        selectAddress (index) {
            const adds = this.orderData.client.addresses[index]
            const delivery = this.deliveries.find(d => d.id === adds.delivery_id)
            this.orderData.delivery.delivery_method = delivery.slug
            this.orderData.delivery.delivery_type = adds.delivery_type
            this.orderData.delivery.cityRecipient = adds.city_recipient
            this.orderData.delivery.warehouse = adds.warehouse
            this.orderData.delivery.entity = adds.entity
            this.orderData.delivery.city = adds.city
            this.orderData.delivery.zip_code = adds.zip_code
            this.orderData.delivery.house_number = adds.house_number
            // search city and warehouse
            this.searchCity(adds.city_name)
            this.searchWarehouse(adds.warehouse_name)
        },
        onSelectWarehouse (item) {
            this.orderData.delivery.warehouse_ref = item.Ref
            this.orderData.delivery.warehouse = item.Description
        }
    }
}
</script>
