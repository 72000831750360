<template>
<div class="table-responsive">
    <table class="table table-striped">
        <tbody>
            <tr>
                <td>
                    <div class="form-group">
                        <label for="status">{{ $t('status') }}<span class="text-danger">*</span></label>
                        <select name="status" class="form-control" v-model="orderData.deliverySummary.orderStatus" :disabled="setDisabledOrderSelect">
                            <option v-for="(item, index) in orderStatuses" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label for="payment">{{ $t('payment') }}<span class="text-danger">*</span></label>
                        <select @change="setPaymentStatuses($event)" name="status" class="form-control" v-model="orderData.deliverySummary.paymentMethod">
                            <option v-for="(item, index) in payments" :key="index" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                </td>
                <td v-if="orderData.delivery.delivery_method !== 'samoviviz'">
                    <div class="form-group">
                        <label for="payment">{{ $t('pays_for_delivery') }}<span class="text-danger">*</span></label>
                        <select name="status" class="form-control" v-model="orderData.deliverySummary.payForDelivery">
                            <option v-for="(item, index) in paysDelivery" :key="index" :value="item.id">{{ item.text }}</option>
                        </select>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label for="payment_status">{{ $t('payment_status') }}<span class="text-danger">*</span></label>
                        <select name="status" class="form-control" v-model="orderData.deliverySummary.paymentStatus" @change="changePaymentStatus">
                            <option v-for="(item, index) in paymentStatuses" :key="index" :value="item.id" :data-text="item.name">{{ item.name }}</option>
                        </select>
                    </div>
                </td>
                <td width="180">
                    <div class="form-group">
                        <label for="">{{ $t('deposit') }}</label>
                        <select class="form-control" v-model="orderData.deliverySummary.depositPaymentType" @change="changeDepositPaymentType">
                            <option value=""> -- </option>
                            <option value="cash">{{ $t('cash') }}</option>
                            <option value="card">{{ $t('card') }}</option>
                        </select>
                    </div>
                </td>
                <td v-if="orderData.deliverySummary.depositPaymentType !== ''">
                    <div class="form-group">
                        <label for="">{{ $t('payment_status') }}</label>
                        <select class="form-control" v-model="orderData.deliverySummary.depositPaymentStatus" @change="changeDepositStatus">
                            <option value="paid">{{ $t('paid') }}</option>
                            <option value="unpaid">{{ $t('unpaid') }}</option>
                        </select>
                    </div>
                </td>
                <td v-if="orderData.deliverySummary.depositPaymentType !== ''" width="100">
                    <div class="form-group">
                        <label for="deposit">{{ $t('deposit') }}</label>
                        <input @change="changeDeposit" type="text" name="deposit" class="form-control" v-model.number="orderData.deliverySummary.deposit">
                    </div>
                </td>
                <td width="120">
                    <div class="form-group">
                        <label for="to_pay">{{ $t('to_pay') }}</label>
                        <input type="text" name="to_pay" class="form-control" v-model.number="orderData.summary.deposit_total" disabled>
                    </div>
                </td>
                <td style="width: 150px">
                    <div class="form-group">
                        <label for="total_dropshipper">{{ $t('total_dropshipper') }}</label>
                        <input type="text" name="total_dropshipper" class="form-control" v-model.number="orderData.summary.total_drop" disabled>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td v-if="deliveryData.deliveryDate"><strong>{{ $t('delivery_date') }}:</strong> <span class="text-success">{{ deliveryData.deliveryDate }}</span></td>
                <td class="text-right" colspan="8">
                    <p><strong>{{ $t('delivery_cost') }}:</strong> {{ deliveryData.deliveryCost | currency }}</p>
                    <p><strong>{{ $t('grand_total') }}:</strong> {{ orderData.summary.total | currency }}</p>
                    <p><strong>{{ $t('total_with_delivery') }}:</strong> {{ orderData.summary.total + deliveryData.deliveryCost | currency }}</p>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    data: () => ({
        paysDelivery: [
            {
                id: 'sender',
                text: 'Відправник',
                selected: false
            },
            {
                id: 'recipient',
                text: 'Одержувач',
                selected: true
            }
        ]
    }),
    mounted () {
        this.setDeliveries()
        this.setPayments()
        this.setStatuses()
        this.updateStatuses()
    },
    computed: {
        ...mapGetters({
            deliveries: 'order/deliveries',
            payments: 'order/paymentsToDelivery',
            statuses: 'order/statuses',
            orderData: 'order/order',
            totalPrice: 'order/totalPrice',
            deliveryData: 'order/deliveryData',
            orderStatuses: 'order/orderStatuses',
            paymentStatuses: 'order/paymentStatuses',
            setDisabledOrderSelect: 'order/setDisabledOrderSelect'
        })
    },
    methods: {
        ...mapActions({
            setDeliveries: 'order/setDeliveries',
            setPayments: 'order/setPayments',
            setStatuses: 'order/setStatuses',
            setOrder: 'order/setOrder',
            updateStatuses: 'order/updateStatuses',
            setPaymentStatuses: 'order/setPaymentStatuses',
            updateTotalPrice: 'order/updateTotalPrice',
            setOrderStatusByRelation: 'order/setOrderStatusByRelation'
        }),
        ...mapMutations({
            setDisableOrderStatus: 'order/SET_DISABLED_ORDER_STATUS'
        }),
        changePaymentStatus (e) {
            if (e.target.options.selectedIndex > -1) {
                const text = e.target.options[e.target.options.selectedIndex].dataset.text
                if (text === 'Частково оплачено') {
                    this.orderData.deliverySummary.deposit_val = 'minus'
                } else if (text === 'Частвово неоплачено') {
                    this.orderData.deliverySummary.deposit_val = 'plus'
                } else if (text === 'Неоплачено') {
                    this.orderData.deliverySummary.deposit = 0
                }
            }
            this.orderData.deliverySummary.paymentStatusDatetime = new Date().toISOString()
            // this.orderData.deliverySummary.deposit = 0
            this.updateTotalPrice()
            this.setOrderStatusByRelation()
        },
        changeDepositStatus (e) {
            const val = e.target.value
            if (val === 'unpaid') {
                // change order status
                const orderStatuses = this.statuses.filter(s => s.type === 'order')
                this.orderData.deliverySummary.orderStatus = orderStatuses.find(s => s.name === 'Робити пізніше').id
                this.setDisableOrderStatus(true)
            } else {
                const orderStatuses = this.statuses.filter(s => s.type === 'order')
                this.orderData.deliverySummary.orderStatus = orderStatuses.find(s => s.name === 'Новий').id
                this.setDisableOrderStatus(false)
                // if status === paid set datetime
                this.orderData.deliverySummary.depositDatetime = new Date().toISOString()
            }
            this.updateTotalPrice()
        },
        changeDepositPaymentType () {
            this.updateTotalPrice()
        },
        changeDeposit () {
            this.updateTotalPrice()
            if (this.orderData.deliverySummary.depositPaymentStatus === 'paid') {
              this.orderData.deliverySummary.depositDatetime = new Date().toISOString()
            }
        },
        updateComponentKey () {
            this.$nextTick(() => {
                // this.forceRerender()
                this.$forceUpdate()
            })
        },
        forceRerender () {
            this.componentKey += 1
            this.componentKeyDelivery += 1
        }
    }
}
</script>
