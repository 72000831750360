<template>
<b-input-group class="mb-2" :key="componentKey">
    <b-form-input min="1" :placeholder="$t('count')" v-model="product.quantity" @change="changeQty($event)"></b-form-input>
    <b-form-input :placeholder="$t('total')" v-model.number="product.total_price" @change="changeProductTotalPrice($event)"></b-form-input>
    <b-form-input :placeholder="$t('total_dropshipper')" v-model.number="product.total_price_drop" disabled></b-form-input>
</b-input-group>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
export default {
    props: {
        product: null,
        characteristicId: null
    },
    data: () => ({
        componentKey: 1100
    }),
    computed: {
        ...mapGetters({
            orderData: 'order/order'
        })
    },
    methods: {
        ...mapActions({
            changeProductQty: 'order/changeProductQty',
            changeTotalClientPrice: 'order/changeTotalClientPrice'
        }),
        changeQty (val) {
            if (val <= 0) {
                Swal.fire({
                    icon: 'warning',
                    title: this.$t('error'),
                    text: this.$t('quantity_not_be_zero'),
                    reverseButtons: true,
                    confirmButtonText: this.$t('ok'),
                    cancelButtonText: this.$t('cancel')
                })
                // this.product.quantity = 1
                this.changeProductQty({ qty: 1, productId: this.product.id })
                this.updateComponentKey()
            } else {
                this.changeProductQty({ qty: Number.parseInt(val), productId: this.product.id })
                this.updateComponentKey()
            }
        },
        changeProductTotalPrice (val) {
            this.changeTotalClientPrice({ price: Number.parseFloat(val), productId: this.product.id, characteristicId: this.characteristicId })
            this.updateComponentKey()
        },
        updateComponentKey () {
            this.$nextTick(() => {
                this.forceRerender()
                // this.$forceUpdate()
            })
        },
        forceRerender () {
            this.componentKey += 1
        }
    }
}
</script>
