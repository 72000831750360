<template>
    <div>
        <b-card v-for="(product, index) in orderData.products" :key="index" class="mt-3" border-variant="info">
            <template v-slot:header>
                <b-button variant="danger" size="sm" @click="removeProduct(product.id)">{{ $t('remove') }}</b-button>
            </template>
            <b-row>
                <b-col sm="4">
                    <div class="form-group">
                        <label for="payment_status">{{ $t('group') }}</label>
                        <select v-model="product.group_id" class="form-control" @change="changeGroup">
                            <optgroup v-for="group in groups" :key="group.id" :label="group.name">
                                <option v-for="child in group.children" :key="child.id" :value="child.id">{{ child.name }}</option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="form-group" v-if="product.group_id">
                        <label for="payment_status">{{ $t('product') }}</label>
                        <model-list-select
                            v-model="product.product_id"
                            :list="products"
                            option-value="id"
                            option-text="title"
                            :placeholder="$t('nothing_selected')"
                            @searchchange="searchProduct(product.group_id, $event)"
                            @input="setSelectedProduct(product.product_id, product.id)"></model-list-select>
                    </div>
                    <div class="form-group">
                        <h6>{{ $t('selected') }}:<span class="text-danger">{{ product.title }}</span></h6>
                    </div>
                    <div v-if="(product.characteristics || []).length > 0">
                        <div class="form-group">
                            <label for="payment_status">{{ $t('characteristics') }}</label>
                            <select v-model="product.characteristic_id" class="form-control" @change="changeCharasteristic(product.id, $event)">
                                <option v-for="item in product.characteristics" :value="item.id" :key="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                        <price
                        v-if="product.characteristic_id"
                        :product="product"
                        :characteristic-id="product.characteristic_id"
                        :key="product.characteristic_id"/>
                        <ul v-if="product.characteristic_id" class="list-unstyled">
                            <li><strong>{{ $t('in_stock') }}:</strong> {{ getQtyByCs(product.characteristic_id).totalQty }}</li>
                            <li><strong>{{ $t('production_is_possible') }}:</strong> {{ getQtyByCs(product.characteristic_id).productionQty }}</li>
                        </ul>
                    </div>
                    <div v-else>
                        <price :product="product"/>
                        <ul class="list-unstyled">
                            <li><strong>{{ $t('in_stock') }}:</strong> {{ product.total_quantity }}</li>
                            <li><strong>{{ $t('production_is_possible') }}:</strong> {{ product.production_quantity }}</li>
                        </ul>
                    </div>
                </b-col>
                <!-- product stages -->
                <stages v-if="product.stages" :product-id="product.id"/>
                <b-col v-else sm="8">
                    <h5 class="text-center">{{ $t('empty') }}</h5>
                </b-col>
            </b-row>
        </b-card>
        <div v-if="orderData.delivery.delivery_method" class="text-center mt-2">
            <b-button variant="secondary" size="sm" @click="addProduct"><fa icon="plus"/>  {{ $t('add_product') }}</b-button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { ModelListSelect } from 'vue-search-select'
import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import Stages from '~/pages/orders/product/stages'
import Price from '~/pages/orders/product/price'
export default {
    components: {
        ModelListSelect,
        Stages,
        Price
    },
    data: () => ({
        groups: [],
        selectedProduct: '',
        products: [],
        selectedProducts: [],
        orderProducts: []
    }),
    computed: {
        ...mapGetters({
            orderData: 'order/order',
            deliveries: 'order/deliveries'
        })
    },
    mounted () {
        this.orderProducts = Object.assign({}, this.orderData)
    },
    mounted () {
        this.fetchGroup()
    },
    methods: {
        ...mapActions({
            updateTotalPrice: 'order/updateTotalPrice',
            setDeliveryData: 'order/setDeliveryData'
        }),
        listToTree (list) {
            const map = {}
            let node
            const roots = []
            for (let i = 0; i < list.length; i += 1) {
                map[list[i].id] = i
                list[i].children = []
            }

            for (let i = 0; i < list.length; i += 1) {
                node = list[i]
                if (node.parent_id > 0) {
                    // if you have dangling branches check that map[node.parentId] exists
                    list[map[node.parent_id]].children.push(node)
                } else {
                    roots.push(node)
                }
            }
            return roots
        },
        async fetchGroup () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await axios.post(apiUrl + 'groups/list').then(resp => {
                this.groups = this.listToTree(resp.data.data)
            })
        },
        changeGroup () {
            this.products = []
        },
        async searchProduct (groupId, key) {
            if (key.length > 3) {
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'products/search', { groupId: groupId, key: key }).then(resp => {
                    this.products = resp.data.data
                })
            }
        },
        setSelectedProduct (productId, uuid) {
            const product = this.products.find(p => p.id === productId)
            this.orderData.products.forEach(p => {
                if (p.id === uuid) {
                    p.product_id = product.id
                    p.title = product.title
                    p.characteristics = []
                    if (Object.keys(product.characteristics.data).length > 0) {
                        p.characteristics = product.characteristics.data.map(c => {
                            return {
                                id: c.id,
                                name: c.name,
                                price: c.price,
                                price_drop: c.price_drop,
                                price_dropshipper: c.price_dropshipper,
                                production_quantity: c.production_quantity,
                                total_quantity: c.total_quantity,
                                stages: c.stages.data.filter(s => {
                                    if (s.visibility) {
                                        return {
                                            id: s.id,
                                            name: s.name,
                                            visibility: s.visibility
                                        }
                                    }
                                })
                            }
                        })
                    } else {
                        p.stages = []
                    }
                    p.quantity = 1
                    p.price = product.price
                    p.price_drop = product.price_drop
                    p.price_dropshipper = product.price_dropshipper
                    p.total_price = 0
                    p.total_price_drop = 0
                    p.total_quantity = Number.parseInt(product.totalQuantity)
                    p.production_quantity = Number.parseInt(product.productionQuantity)

                    console.log(product)
                }
            })
            this.setDeliveryPrice()
            this.updateTotalPrice()
        },
        changeCharasteristic (uuid, e) {
            const characteristicId = e.target.value
            if (characteristicId.length > 0) {
                // eslint-disable-next-line prefer-const
                let product = this.orderData.products.find(p => p.id === uuid)
                if (product.characteristics) {
                    const charactr = product.characteristics.find(c => c.id === characteristicId)
                    if (charactr.stages) {
                        product.stages = charactr.stages.filter(s => {
                            return {
                                id: s.id,
                                name: s.name
                            }
                        })
                    } else {
                        product.stages = []
                    }
                    product.stagesSelected = []
                    product.stage_id = null
                    product.total_price = 0
                    product.total_price_drop = 0
                }
            }
            this.updateTotalPrice()
        },
        addProduct () {
            this.orderData.products.push({
                id: uuidv4(),
                group_id: ''
            })
        },
        removeProduct (id) {
            const product = this.orderData.products.findIndex(p => p.id === id)
            this.orderData.products.splice(product, 1)
            this.updateTotalPrice()
        },
        getQtyByCs (id) {
            let data = { totalQty: 0, productionQty: 0 }
            if ((this.orderData.products || []).length > 0) {
                this.orderData.products.forEach(p => {
                    if (typeof p.characteristics !== 'undefined') {
                        const characteristic = p.characteristics.find(c => c.id === id)
                        if (typeof characteristic !== 'undefined') {
                            data = {
                                totalQty: characteristic.total_quantity,
                                productionQty: characteristic.production_quantity
                            }
                        }
                    }
                })
                return data
            }
        },
        async setDeliveryPrice () {
            const products = this.orderData.products.map(p => {
                return p.product_id
            })
            if (this.orderData.delivery.delivery_method === 'nova-posta') {
                const citySender = this.deliveries.find(d => d.slug === this.orderData.delivery.delivery_method)
                const data = {
                    deliveryMethod: this.orderData.delivery.delivery_method,
                    products: products,
                    citySender: citySender.city_ref,
                    cityRecipient: this.orderData.delivery.cityRecipient,
                    deliveryType: this.orderData.delivery.delivery_type,
                    totalPrice: this.orderData.summary.total
                }
                this.getDeliveryPrice(data)
            } else if (this.orderData.delivery.delivery_method === 'ukr-posta') {
                const data = {
                    deliveryMethod: this.orderData.delivery.delivery_method,
                    products: products,
                    cityRecipient: this.orderData.delivery.cityRecipient,
                    deliveryType: this.orderData.delivery.delivery_type,
                    shipmentType: this.orderData.delivery.shipment_type,
                    postcode: this.orderData.delivery.zip_code,
                    totalPrice: this.orderData.summary.total
                }
                this.getDeliveryPrice(data)
            }
        },
        async getDeliveryPrice (params) {
            this.$root.$refs.loading.start()
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await axios.post(apiUrl + 'deliveries/price', params).then(resp => {
                if (resp.data) {
                    this.setDeliveryData(resp.data)
                    this.$root.$refs.loading.finish()
                }
            })
        }
    }
}
</script>
