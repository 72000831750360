<template>
    <b-card :title="`${$t('create')}`" v-if="orderData">
            <b-tabs content-class="mt-3">
                <client />
                <b-tab :title="$t('package')">
                    <!-- delivery component -->
                    <delivery key="delivery-data"/>
                    <deliveryStatus key="delivery-status-data" />
                    <product key="product-data"/>
                </b-tab>
                <b-tab :title="$t('additional')">
                    <div class="form-group">
                        <label for="comment">{{ $t('comment') }}</label>
                        <textarea type="text" name="mark" class="form-control" v-model="orderData.comment"></textarea>
                    </div>
                </b-tab>
            </b-tabs>
            <b-progress v-if="loading" height="2rem" max="100" animated variant="primary">
                <b-progress-bar value="100">
                    {{ $t('wait_order_saving') }}
                </b-progress-bar>
            </b-progress>
            <br>
            <b-button v-if="!loading" :disabled="loading" variant="success" @click="preorder">{{ $t('save') }}</b-button>
    </b-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Client from '~/pages/orders/client'
import Delivery from '~/pages/orders/delivery'
import Product from '~/pages/orders/product'
import DeliveryStatus from '~/pages/orders/deliveryStatus'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('create') }
    },
    components: {
      Client,
      Delivery,
      DeliveryStatus,
      Product
    },
    data: () => ({
        customers: [],
        selectedCustomer: {},
        searchText: '',
        sendingOrderStatus: false
    }),
    mounted () {
        const order = {
            id: uuidv4(),
            orderType: 1,
            delivery: {
                delivery_method: '',
                delivery_method_id: '',
                delivery_type: '',
                city: '',
                warehouse: '',
                warehouse_ref: '',
                entity: 'individual',
                zip_code: '',
                apartment_number: '',
                house_number: '',
                delivery: '',
                delivery_date: '',
                delivery_status: '',
                city_name: '',
                street: '',
                delivery_address: '',
                shipment_type: 'EXPRESS',
                check_on_delivery_allowed: true
            },
            order_num: null,
            comment: '',
            summary: {
                total: 0,
                total_drop: 0,
                delivery_cost: 0
            },
            deliverySummary: {
                orderStatus: '',
                paymentMethod: '',
                payForDelivery: 'recipient',
                paymentStatus: '',
                deposit: 0,
                depositPaymentType: '',
                depositPaymentStatus: 'card',
                delivery_data: {},
                payment_type: ''
            },
            created_at: '',
            client: {},
            products: { data: [] }
        }
        this.setOrder(order)
    },
    computed: {
        ...mapGetters({
            orderData: 'order/order',
            loading: 'order/loading',
            orderNum: 'order/orderNum'
        })
    },
    methods: {
        ...mapActions({
            setOrder: 'order/setOrder',
            saveOrder: 'order/saveOrder'
        }),
        preorder () {
            if (this.orderData.products.length > 0) {
                this.sendingOrderStatus = true
                this.saveOrder()
                setTimeout(() => {
                    if (this.orderNum) {
                        this.sendingOrderStatus = false
                        this.$router.push({ name: 'orders' })
                    }
                }, 2000)
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: this.$t('error_product_not_selected'),
                    text: this.$t('error_product_not_selected_text'),
                    reverseButtons: true,
                    confirmButtonText: this.$t('ok'),
                    cancelButtonText: this.$t('cancel')
                })
                this.sendingOrderStatus = false
            }
        },
        updateComponentKey () {
            this.$nextTick(() => {
                // this.forceRerender()
                this.$forceUpdate()
            })
        },
        forceRerender () {
            this.componentKey += 1
            this.componentKeyDelivery += 1
        }
    }
}
</script>
