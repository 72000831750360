<template>
    <b-tab key="1" :title="$t('client')" active>
        <h5>Пошук по номеру телефону</h5>
        <b-row>
            <b-col sm="6">
                <div class="form-group">
                    <label for="client">{{ $t('client') }}<span class="text-danger">*</span></label>
                    <model-list-select
                        :list="customers"
                        option-value="id"
                        option-text="phone"
                        v-model="selectedCustomer"
                        :placeholder="$t('nothing_selected')"
                        @searchchange="searchClient"></model-list-select>
                </div>
            </b-col>
        </b-row>
        <h5>Додати нового</h5>
        <b-row>
            <b-col sm="6">
                <div class="form-group">
                    <label for="first_name">{{ $t('first_name') }}<span class="text-danger">*</span></label>
                    <input type="text" name="first_name" class="form-control" v-model="orderData.client.first_name">
                </div>
                <div class="form-group">
                    <label for="last_name">{{ $t('last_name') }}<span class="text-danger">*</span></label>
                    <input type="text" name="last_name" class="form-control" v-model="orderData.client.last_name">
                </div>
                <div class="form-group">
                    <label for="surname">{{ $t('patronimic_name') }}<span class="text-danger">*</span></label>
                    <input type="text" name="surname" class="form-control" v-model="orderData.client.surname">
                </div>
                <div class="form-group">
                    <label for="phone">{{ $t('phone') }}<span class="text-danger">*</span></label>
                    <input v-model="orderData.client.phone" v-mask="mask" class="form-control" />
                </div>
                <div class="form-group">
                    <label for="email">{{ $t('email') }}</label>
                    <input type="text" name="email" class="form-control" v-model="orderData.client.email">
                </div>
            </b-col>
            <b-col sm="6">
                <div class="form-group">
                    <label for="client_contact">{{ $t('client_contact') }}</label>
                    <select name="client_contact" class="form-control" v-model="orderData.client.contact_type">
                        <option value="Viber">Viber</option>
                        <option value="WhatsApp">WhatsApp</option>
                        <option value="WhatsApp">Instagram</option>
                        <option value="Messanger">Messanger</option>
                        <option value="Телефон">Телефон</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="contact">{{ $t('contact') }}</label>
                    <input type="text" name="contact" class="form-control" v-model="orderData.client.contact">
                </div>
                <div class="form-group">
                    <label for="group">{{ $t('group') }}</label>
                    <select name="group" class="form-control" v-model="orderData.client.group_id">
                        <option v-for="(item, index) in groups" :value="item.id" :key="index">{{ item.name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="company">{{ $t('company') }}</label>
                    <input type="text" name="company" class="form-control" v-model="orderData.client.company">
                </div>
                <div class="form-group">
                    <label for="description">{{ $t('description') }}</label>
                    <textarea name="description" class="form-control" v-model="orderData.client.description"></textarea>
                </div>
            </b-col>
        </b-row>
    </b-tab>
</template>
<script>
import axios from 'axios'
import { ModelListSelect } from 'vue-search-select'
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        mask: ['+38', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        customers: [],
        selectedCustomer: {},
        searchText: '',
        groups: null
    }),
    components: {
        ModelListSelect
    },
    mounted () {
        this.fetchGroups()
    },
    computed: {
        ...mapGetters({
            orderData: 'order/order'
        })
    },
    watch: {
        selectedCustomer: function (val) {
            this.orderData.client.id = val.id
            this.orderData.client.first_name = val.first_name
            this.orderData.client.last_name = val.last_name
            this.orderData.client.surname = val.surname
            this.orderData.client.phone = val.phone
            this.orderData.client.email = val.email
            this.orderData.client.group_id = val.group_id
            this.orderData.client.company = val.company
            this.orderData.client.description = val.description
            this.orderData.client.addresses = val.addresses.data
        }
    },
    methods: {
        async searchClient (value) {
            if (value.length > 5) {
                this.searchText = value
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'customers/search', { phone: value }).then(resp => {
                    this.customers = resp.data.data
                })
            }
        },
        async fetchGroups () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'customer-groups')
            this.groups = data.data
        },
        onSelectSearch () {
            console.log(this.selectedCustomer)
        }
    }
}
</script>
